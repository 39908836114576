<template>
  <div class="card">
    <div class="title">出口商品</div>
    <div class="select">
      <el-radio-group v-model="F_NtervalTime" @change="changeSelect">
        <el-radio-button v-for="item in timeList" :key="item.value" :label="item.label"  class="select-item"></el-radio-button>
      </el-radio-group>
    </div>
    <div class="goods-table">
      <el-table
          class="customer-table"
          :data="orderInfo"
          height="100%"
          width="100%"
          ref="TableData"
          stripe
          style="width: 100%;">
        <el-table-column
            label="排名"
            width="15%"
        >
          <template v-slot="scope">
            {{ scope.$index + 1 }}
          </template>
        </el-table-column>
        <el-table-column
            prop="F_ProductTypeName"
            label="商品类型"
            width="30%"
        >
        </el-table-column>
        <el-table-column
            label="交易占比"
            width="30%">
          <template v-slot="scope">
            <div style="display: flex;align-items: center">
              <div class="goods-number">{{ scope.row.F_TransactionProportion }}%</div>
              <div class="goods-block"
                   :style="{width: (scope.row.F_TransactionProportion/2) + 'px'}"></div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
            label="现货比重"
            width="25%">
          <template v-slot="scope">
            <div class="up" v-if="scope.row.F_RiseDecline === 1">
              <img src="@/assets/leftIcon/up.png" alt="">
              {{ scope.row.F_SpotWeight }}%
            </div>
            <div class="down" v-if="scope.row.F_RiseDecline === 2">
              <img src="@/assets/leftIcon/down.png" alt="">
              {{ scope.row.F_SpotWeight }}%
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <border-line></border-line>
  </div>

</template>

<script>
import BorderLine from "@/components/BorderLine/BorderLine.vue";
export default {
  name: "outboundGoodsTable",
  components: {BorderLine},
  data() {
    return {
      timer: null,
      timer1: null,
      currentIndex: 0, // 当前选中的时间区间的索引，默认为0表示日
      F_ProductCategory: 2, //类型（0平台 1进口 2出口）
      F_NtervalTime: 5, //时间区间（1日  2 周  3月 4季度 5年）
      orderInfo: [],
      timeList: [
        {
          label: '日',
          value: 1,
        },
        {
          label: '周',
          value: 2,
        },
        {
          label: '月',
          value: 3,
        },
        {
          label: '年',
          value: 5,
        },
      ]
    }
  },
  mounted() {
    this.startScroll();
  },
  destroyed() {
    this.stopScroll();
  },
  created() {
    this.getData()
  },
  methods: {
    startScroll() {
      this.stopScroll(); // 确保只有一个定时器在运行
      this.timer = setInterval(() => {
        const tableEl = this.$refs.TableData.$el.querySelector(".el-table__body-wrapper");
        if (tableEl) {
          const scrollTop = tableEl.scrollTop;
          const scrollHeight = tableEl.scrollHeight;
          const clientHeight = tableEl.clientHeight;
          if (scrollTop + clientHeight >= scrollHeight) {
            tableEl.scrollTop = 0; // 滚动到顶部
          } else {
            tableEl.scrollTop += 1; // 每次滚动的距离
          }
        }
      }, 50); // 滚动的间隔时间
    },
    stopScroll() {
      if (this.timer) {
        clearInterval(this.timer); // 停止定时器
        this.timer = null;
      }
    },
    async getData() {
      const data = {
        F_ProductCategory: this.F_ProductCategory,
        F_NtervalTime: this.F_NtervalTime
      }
      let res = await this.api.getProListData(data)
      this.orderInfo = res.data
    },
    changeSelect(val) {
      let i = this.timeList.filter(item => item.label === val )[0]
      this.F_NtervalTime = i.value
      this.getData()
    }
  }
}
</script>

<style scoped>
.card {
  width: 338px;
  height: 364px;
  position: relative;
  overflow: hidden;
}
.customer-table::-webkit-scrollbar {display:none}

.customer-table{
  width: 100%;
  height: 90%;
  overflow: scroll;
}
.title {
  width: 100%;
  padding: 10px 0;
  background: linear-gradient(90deg, rgba(5, 63, 152, 0) 0%, #053F98 47%, rgba(5, 63, 152, 0) 100%);
  font-size: 16px;
  color: #00CAF0;
}

.goods-number {
  width: 36px;
  font-size: 12px;
  color: #A8C5F8;
}

.goods-block {
  height: 8px;
  background: #00CAF0;
  border-radius: 1px 1px 1px 1px;
  opacity: 1;
}

.down {
  font-size: 12px;
  color: #FF5151;
}

.up {
  font-size: 12px;
  color: #0DD699;
}

/* radio */
.select {
  padding: 12px 24px;
}

/deep/ .el-radio-button .el-radio-button__inner {
  font-size: 12px;
  padding: 8px 9px;
  background: #001448;
  color: #4A9EFF;
  border: 1px solid #4A9EFF !important;
}

/deep/ .el-radio-button__orig-radio:checked + .el-radio-button__inner {
  background-color: #022568;
  color: #4A9EFF;
}


/* table */
/deep/ .el-table, .el-table__expanded-cell {
  background: rgba(255, 255, 255, 0) !important;
}

.goods-table {
  height: 270px;
  overflow: hidden;
}

/deep/ .el-table .el-table__cell {
  padding: 11px 0;
}

/deep/ .el-table__header {
  width: 100% !important;
}

/deep/ .el-table td.el-table__cell, .el-table th.el-table__cell.is-leaf {
  border-bottom: 0;
}

.el-table__header-wrapper .is-leaf {
  border-bottom: 0;
}

/deep/ .el-table th.el-table__cell {
  background: #002e70 40% !important;
  color: #FFFFFF;
  font-size: 12px;
  text-align: center;
}

/deep/ .el-table__body {
  width: 100% !important;
}

/deep/ .el-table tr {
  background: #002E70;
}

/deep/ .el-table--enable-row-hover .el-table__body tr:hover > td.el-table__cell {
  background: #002f70 40%;
}

/deep/ .el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
  background: #002f70;
}

/deep/ .el-table td,
.building-top .el-table th.is-leaf {
  border: none;
  color: white;
}

/deep/ .el-table__body-wrapper {
  scrollbar-width: none;
}

/deep/ .el-table__cell {
  background: #001e58;
}

/deep/ .el-radio-button__inner {
  border: none;
}

/deep/ .el-radio-button:first-child .el-radio-button__inner {
  border: none;
}

/deep/ .el-table__body-wrapper::-webkit-scrollbar {
  width: 0;
  display: none;
}

/deep/ .el-table th.gutter {
  width: 0 !important;
  height: 0;
  display: none;
}

/deep/ .el-table colgroup col[name='gutter'] {
  display: none;
  width: 0;
}

/deep/ .el-table--border::after, .el-table--group::after, .el-table::before {
  content: none;
}
</style>
