import request from "./http.js";

export const baseUrl = "https://mgr.hngqkg.com";
// export const baseUrl = "http://192.168.10.81:89";
// export const baseUrl = 'http://admin.zh.yueyiweb.com/'

export default {
    //订单交易数据   共用
    getOrderTransactionData() {
        return request.requestPost(baseUrl + '/api/DataStatistics/GetOrderTransactionData')
    },
    //左边
    //进出口比例
    getProductOutIn() {
        return request.requestPost(baseUrl + '/api/DataStatistics/GetProductOutIntData')
    },

    // 进出口商品
    getProListData(data) {
        return request.requestPost(baseUrl + '/api/DataStatistics/GetProListData', data)
    },

    getExportData(data) {
        return request.requestPost(baseUrl + '/api/DataStatistics/GetExportData', data)
    },

    //进口商品折线图
    getImportData(data) {
      return request.requestPost(baseUrl + '/api/DataStatistics/GetImportData', data)
    },

    // 物流数据
    getOrderLogisticsData() {
        return request.requestPost(baseUrl + '/api/DataStatistics/GetOrderLogisticsData')
    },

    // 中间
    //迁徙图
    getContainerMigrationData() {
      return request.requestPost(baseUrl + '/api/DataStatistics/GetContainerMigrationData')
    },

    // 进出口排行
    getCompanyRListData() {
        return request.requestPost(baseUrl + '/api/DataStatistics/GetCompanyRListData', {F_Type: 1})
    },

    //船司延误
    getDelayListData() {
        return request.requestPost(baseUrl + '/api/DataStatistics/GetCompanyRListData', {F_Type: 2})
    },

    // 出入库量/面积排行
    getWarehouseMapData() {
        //（0全部 1自营  2云仓）
        return request.requestPost(baseUrl + '/api/DataStatistics/GetWwarehouseMapData', {F_Type: 0})
    },
    //仓库入库所有值
    getWwarehouseMapDataAll(data) {
        //（0全部 1自营  2云仓）
        return request.requestPost(baseUrl + '/api/DataStatistics/GetWwarehouseMapData',data)
    },
    // 右边


    //大宗商品价格涨跌数据获取
    getGoodsPriceFluctuationsData() {
        return request.requestPost(baseUrl + '/api/DataStatistics/GetGoodsPriceFluctuationsData')
    },
    // 订单区域交易占比数据获取
    getOrderRegionTransaction(data) {
        return request.requestPost(baseUrl + '/api/DataStatistics/GetOrderRegionTransactionProportionData',data)
    },
    //中非主要港口进出口柜子数量总数
    //中国
    getPortOutInListData() {
        return request.requestPost(baseUrl + '/api/DataStatistics/GetPortOutInListData',{F_AreaType:1})
    },
    getPortOutInListTwoData() {
        return request.requestPost(baseUrl + '/api/DataStatistics/GetPortOutInListTwoData',{F_AreaType:1})
    },
    //非洲
    getPortOutInListDataFei() {
        return request.requestPost(baseUrl + '/api/DataStatistics/GetPortOutInListData',{F_AreaType:2})
    }
};
