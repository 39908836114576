<template>
    <div class="wrap">
      <dv-border-box-1 :reverse="true">
        <div style="display: flex;justify-content: space-between;padding:20px 15px">
          <div class="card" v-for="(item,index) in styleList" :key="index">
            <div class="line1">
              <div>{{ item.title }}</div>
              <div class="block" :class="item.color"></div>
            </div>
            <div class="line2">
              <img class="line2-icon" :src="item.imgUrl" alt="">
              <el-statistic class="line2-number" :class="item.color" group-separator=","
                            :value="cardData[index].F_Number"></el-statistic>
            </div>
            <div class="line3">
              <div class="line3-item up" v-if="cardData[index].F_RiseDecline === 1">
                <img class="line3-icon" src="@/assets/leftIcon/up.png" alt="">
                <span>{{ cardData[index].F_TransactionProportion }}%</span>
              </div>
              <div class="line3-item down" v-else>
                <img class="line3-icon" src="@/assets/leftIcon/down.png" alt="">
                <span>{{ cardData[index].F_TransactionProportion }}%</span>
              </div>
              <span>同比上周</span>
            </div>
          </div>

        </div>
<!--        <border-line></border-line>-->
      </dv-border-box-1>
    </div>
</template>

<script>
// import BorderLine from "@/components/BorderLine/BorderLine.vue";

export default {
    // name: "TopWrap",
    // components: {BorderLine},
    data() {
        return {
            styleList: [
                {
                    title: '进行中订单',
                    imgUrl: require('@/assets/leftIcon/icon-1.png'),
                    color: 'orange',
                },
                {
                    title: '已完成订单',
                    imgUrl: require('@/assets/leftIcon/icon-2.png'),
                    color: 'blue',
                },
                {
                    title: '平台出柜量',
                    imgUrl: require('@/assets/leftIcon/icon-3.png'),
                    color: 'red',
                },
                {
                    title: '电商成交量',
                    imgUrl: require('@/assets/leftIcon/icon-4.png'),
                    color: 'purple',
                },
            ],
            cardData: [
                {
                    F_Number: 0,
                },
                {
                    F_Number: 0,
                },
                {
                    F_Number: 0,
                },
                {
                    F_Number: 0,
                },
            ],
        }
    },
    created() {
        this.getData()
    },
    methods: {
        getData() {
            const name = ['进行中订单', '已完成订单', '平台出柜数量', '电商成交量']
            this.api.getOrderTransactionData().then(res => {
                    this.cardData = res.data.filter(item => name.includes(item.F_OrderCategoryName));
                    this.cardData.sort((a, b) => name.indexOf(a.F_OrderCategoryName) - name.indexOf(b.F_OrderCategoryName));
                }
            )
        }
    }
}
</script>

<style scoped>
.wrap {
    width: 1150px;
    height: 170px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgba(0, 46, 112, 0.3);
}

.card {
    width: 266px;
    height: 122px;
    background: #07225b;
    border-radius: 8px;
}

.line1 {
    font-size: 14px;
    color: white;
    text-align: right;
    margin: 16px 22px 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.block {
    margin-left: 6px;
    width: 2px;
    height: 12px;
    opacity: 1;
}

.line2 {
    margin: 0 22px;
    display: flex;
    align-items: center;
}

.line2-number {
    margin-left: 12px;
    background: rgba(255, 255, 255, 0) !important;
}

/deep/ .el-statistic .con .number {
    font-size: 24px;
    font-weight: bold;
}

/deep/ .el-statistic .con {
    color: inherit;
    justify-content: start;
}

.orange {
    background: #FA9B5B;
    color: #FA9B5B !important;
}

.blue {
    background: #00CAF0;
    color: #00CAF0 !important;
}

.red {
    background: #FE748B;
    color: #FE748B !important;
}

.purple {
    background: #E382EE;
    color: #E382EE !important;
}

.line3 {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    padding: 0 22px 16px;
    color: rgba(255, 255, 255, 0.6);
    font-size: 14px;
}

.line3-item {
    display: flex;
    align-items: center;
    margin-right: 12px;
}

.line3-icon {
    width: 10px;
    height: 6px;
}

.down {
    color: #FF5151;
}

.up {
    color: #0DD699;
}
</style>
