<template>
    <div class="card">
        <div class="title">{{ title }}</div>
        <el-table class="customer-table"  :data="tableData" header-row-class-name="tableHead"
        >
            <el-table-column label="排名" width="60">
                <template v-slot="scope">
                    <div>
                        {{ scope.$index + 1 }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column v-for="(item,index) in headList" :key="index" :label="item.name" :prop="item.prop"
                             :width="item.width" :class-name="item.className"></el-table-column>
        </el-table>
        <BorderLine></BorderLine>
    </div>
</template>

<script>
import BorderLine from "@/components/BorderLine/BorderLine.vue";

export default {
    components: {BorderLine},
    props: {
        title: {
            type: String,
            default: ''
        },
        headList: {
            type: [],
            default: () => ([])
        },
        tableData: {
            type: [],
            default: () => ([])
        }
    },
    data() {
        return {}
    }
}
</script>

<style>

</style>
<style scoped>
::-webkit-scrollbar {
  /*滚动条整体样式*/
  width : 10px;  /*高宽分别对应横竖滚动条的尺寸*/
  height: 10px;
}
::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  box-shadow   : inset 0 0 5px rgba(0, 0, 0, 0.2);
  background   : #535353;
}
::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  box-shadow   : inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background   : #ededed;
}
.customer-table{
  width: 100%;
  height: 90%;
  overflow: scroll;
}
.customer-table::-webkit-scrollbar {display:none}
.card {
    width: 342px;
    height: 306px;
    position: relative;
    overflow: hidden; /* 隐藏溢出内容 */
}

.title {
    width: 100%;
    padding: 20px 0;
    font-size: 16px;
    color: #4A9EFF;
    background: #001448 20%;
}
/deep/.table-wrapper::-webkit-scrollbar { display: none; }
/deep/ .el-table, .el-table__expanded-cell{
    background: rgba(255, 255, 255, 0) !important;
}

/deep/ .tableHead {
    color: #FFFFFF !important;
}

/deep/ .el-table th.el-table__cell > .cell {
    font-weight: 400;
}

/deep/ .el-table th.el-table__cell {
    background: #001e58;
}

/deep/ .el-table tr {
    background: #001448;
    color: white;
}

/deep/ .el-table .el-table__cell {
    padding: 9px 0;
    font-size: 12px;
    text-overflow: ellipsis;
}

/deep/ .el-table .cell {
    white-space: nowrap;
}

/deep/ .el-table--enable-row-hover .el-table__body tr:hover > td.el-table__cell {
    background: #001e58;
}

/deep/ .el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
    background: #001e58;
}

/deep/ .el-table td.el-table__cell, .el-table th.el-table__cell.is-leaf {
    border-bottom: none;
}

/deep/ .tableName {

}

/deep/ .el-table__row .tableNumber {
    color: #4A9EFF;
}

/deep/ .el-table--border::after, .el-table--group::after, .el-table::before {
    content: none;
}
</style>
