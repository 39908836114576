import axios from 'axios';

// 设置超时时间
axios.defaults.timeout = 100000;
export default {

    requestPost(url, data) {
        return new Promise((resolve, reject) => {
            axios.post(url, data,{
                "Content-Type": "application/json"
            }).then(res => {
                resolve(res.data)
            }).catch(error => {
                reject(error)
            });
        });
    },
}
