<template>
    <v-scale-screen width="3840" height="1080" autoScale>
        <div id="app">
            <div class="head">
                <div></div>
                <span class="title">中非大宗商品交易中心数据可视化平台</span>
                <div class="other-info">
                    <img width="14" height="14" src="../src/assets/time-icon.png" class="time-icon"/>
                      <span>{{ nowDate }} </span>
            <span class="week-text">{{ nowWeek }}</span>
            <span>{{ dateFormat(nowTime) }}</span>
                </div>
            </div>
            <div style="display: flex;width: 100%;flex-wrap: wrap;">
                <!--        left        -->
                <div class="side">
                    <TopWrap></TopWrap>
                    <div style="display: flex;margin: 16px 0;">
                        <import-goods-table style="margin-right: 16px"></import-goods-table>
                        <outboundGoodsTable style="margin-right: 16px"></outboundGoodsTable>
                        <div>
                            <IEProportion></IEProportion>
                            <OrderInfo style="margin-top: 16px"></OrderInfo>
                        </div>
                    </div>
                    <div style="display: flex;">
                        <ImportLineChart style="margin-right: 16px"></ImportLineChart>
                        <ExportLineChart></ExportLineChart>
                    </div>
                </div>
                <!--        middle        -->
                <div class="middle">
                    <div style="display: flex;justify-content: space-between">
                        <LineMap></LineMap>
                      <WarehouseMap></WarehouseMap>
                    </div>
                    <div style="margin-top: 16px">
                        <all-table></all-table>
                    </div>
                </div>
                <!--        right        -->
                <div class="side">
                    <div>
                        <common-wrap :total="100">
                        </common-wrap>
                    </div>
                    <div class="ContainerOrders" style="margin-top: 16px;margin-bottom: 16px">
                            <PercentageGoods></PercentageGoods>
                            <common-fanning></common-fanning>
                            <RiseandFall></RiseandFall>
                    </div>
                    <div class="ContainerOrders">
                            <CommonCircular></CommonCircular>
                            <CommonCircuFei></CommonCircuFei>
                    </div>
                </div>
            </div>
        </div>
    </v-scale-screen>
</template>

<script>
import AMapLoader from '@amap/amap-jsapi-loader'
import VScaleScreen from 'v-scale-screen'
import CommonWrap from '@/components/CommonWrap'
import PercentageGoods from "@/components/PercentageGoods";
// import containerExit from "@/components/ContainerExit";
import CommonFanning from "@/components/CommonFanning";
import CommonCircular from "@/components/CommonCircular/index";
import CommonCircuFei from "@/components/CommonCircular/feiIndex"
import RiseandFall from "@/components/RiseandFall";
import LineMap from "@/components/Middle/LineMap.vue";
import WarehouseMap from "@/components/Middle/WarehouseMap";
import TopWrap from "@/components/Left/TopWrap.vue";
import ImportLineChart from "@/components/Left/ImportLineChart.vue";
import IEProportion from "@/components/Left/IEProportion.vue";
import OrderInfo from "@/components/Left/OrderInfo.vue";
import ImportGoodsTable from "@/components/Left/ImportGoodsTable.vue";
import AllTable from "@/components/Middle/AllTable.vue";
import ExportLineChart from "@/components/Left/ExportLineChart.vue";
import outboundGoodsTable from "@/components/Left/outboundGoodsTable"

export default {
    name: 'App',
    components: {
      outboundGoodsTable,
        AllTable,
        ImportGoodsTable,
        OrderInfo,
        IEProportion,
        ImportLineChart,
        TopWrap,
        LineMap,
      WarehouseMap,
        // eslint-disable-next-line vue/no-unused-components
        VScaleScreen,
        CommonWrap,
        // containerExit,
        CommonFanning,
        CommonCircular,
      CommonCircuFei,
        RiseandFall,
        PercentageGoods,
        ExportLineChart
    },
    data() {
        return {
          nowDate: '',//当前日期
          nowWeek: '',//当前星期
          nowTime: new Date().getTime(),//当前时间
          nowCity: '',//当前城市
          nowWeather: '',//当前天气
          nowAirIndex: '',//当前空气指数
          weekMap: {
            0: '周日',
            1: '周一',
            2: '周二',
            3: '周三',
            4: '周四',
            5: '周五',
            6: '周六',
          },
          position: null

        }
    },
    created() {
      window._AMapSecurityConfig = {
        securityJsCode: 'fbeb49d7277967e61e9cf8a4e52f65ee',
      }
      const nowDate = new Date()
      this.nowDate = `${nowDate.getFullYear()}-${nowDate.getMonth() + 1}-${nowDate.getDate()}`
      this.nowWeek = this.weekMap[nowDate.getDay()]
      setInterval(() => {
        this.nowTime = new Date().getTime()
      }, 1000)
      // 获取地理位置信息
      navigator.geolocation.getCurrentPosition((position) => {
        this.position = position
      })
      this.initLoacl()

    },
    methods: {
      // 时间格式化
      dateFormat(time = new Date().getTime()) {
        var date = new Date(time)
        var hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
        var minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
        var seconds = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
        // 拼接 时间格式处理
        return `${hours}:${minutes}:${seconds} `
      },
      async initLoacl() {
        const amap = await AMapLoader.load({
          key: '1df8c5f167f75e4af7d5301d67a398d5',
          version: '2.0',
          plugins: ['AMap.Weather']
        })
        const res = new amap.Weather()
        res.getLive("北京", (req,data) => {
          console.log(req);
          console.log(data);
        })
      }
    }
}
</script>

<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /*text-align: center;*/
    color: #2c3e50;
    background-color: #000937;
    padding: 0 30px;
}

.side {
    width: 1152px;
}

.middle {
    width: 1416px;
    margin: 0 16px;
}

.title {
    text-align: center;
    font-size: 30px;
    font-weight: bold;
    color: #ffffff;
}

.ContainerOrders {
    display: flex;
    justify-content: space-between;
    /*display: flex;*/
    /*justify-content: space-between;*/
}

.head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 129px;
}

.time-icon {
    margin-right: 10px;
}

.other-info {
    font-size: 14px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #C1DEFF;
}

.week-text {
    margin: 0 20px;
}
.el-table td.el-table__cell, .el-table th.el-table__cell.is-leaf {
    border-bottom: 0 !important;
}
.customer-table thead tr th:nth-last-of-type(2){
    border: none;
}
</style>
