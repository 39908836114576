<template>
  <div class="map-box">
    <div class="title">仓库分布图</div>
    <div class="title-line"></div>
    <div class="select">
      <el-radio-group v-model="F_NtervalTime" @change="changeSelect">
        <el-radio-button v-for="item in timeList" :key="item.value" :label="item.label"  class="select-item"></el-radio-button>
      </el-radio-group>
    </div>
    <div class="map" id="WarehouseMap"></div>
    <BorderLine></BorderLine>
  </div>
</template>
<script>
import {Site} from "@/assets/js/mapSite";
import {getckEnd,lightArea, worldNameZh} from "@/assets/js/mapTool";
import {world} from "@/assets/js/world";
import BorderLine from "@/components/BorderLine/BorderLine.vue";

export default {
  name: "WarehouseMap",
  components: {BorderLine},
  data(){
    return{
      lineData: [],
      endData: [],
      //产品类型
      F_ProductTypeName:[],
      F_NtervalTime:1,
      timeList: [
        {
          label: '全部',
          value: 0,
        },
        {
          label: '自营仓',
          value: 1,
        },
        {
          label: '代理仓',
          value: 2,
        },

      ]
    }
  },
  mounted() {
    this.getData()


  },
  methods: {
    getData() {
      const data = {
        F_Type:this.F_NtervalTime
      }
      console.log(data)
      this.api.getWwarehouseMapDataAll(data).then(res => {
        this.lineData = res.data
        this.endData = getckEnd(res.data)
        this.draw()
      })
    },
    changeSelect(val) {
      let i = this.timeList.filter(item => item.label === val )[0]
      this.F_NtervalTime = i.value
      this.getData()
    },
    draw(){
      let myChart = this.$echarts.init(document.getElementById('WarehouseMap'));
      this.$echarts.registerMap('world', world)
      let option = {
        geo: [
          {
            type: 'map',
            map: 'world',
            nameMap: worldNameZh,
            roam: false,
            zoom: 2.1,
            center: [80.617733, 0.792818],
            emphasis: {
              label: {
                show: false
              }
            },
            itemStyle: {
              normal: {
                borderWidth: 2,
                borderColor: '#2065d5',
                areaColor: '#2065d5',
              },
              emphasis: {
                areaColor: '#2B7BDC' // 鼠标悬浮时的颜色
              }
            },
            regions: lightArea,
            selectedMode: false,
          }
        ],
        tooltip: {
          backgroundStyle: {
            color: 'red'
          }
        },
        series: [
          {
            type: "lines",
            coordinateSystem: "geo",
            z: 99,
            zlevel: 2,
            effect: {
              show: true,
              period: 6,//箭头指向速度,值越小速度越快
              trailLength: 0.3,//特效尾迹长度[0,1]值越大，尾迹越长重
              symbol: 'circle',//箭头图标
              symbolSize: 12,//图标大小
            },
            tooltip: {
              show: false,
            },

          },
          {
            name: '仓库起点',
            type: 'effectScatter',
            coordinateSystem: 'geo',
            layout: 'none',
            data:this.lineData.map(item=>{
              return {
                value : [item.F_Latitude,item.F_Dimension],
                //产品类型
                F_ProductTypeName:item.F_ProductTypeName,
              //  仓库面积
                F_Area:item.F_Area,
              //  出货柜量
                F_ComingOut:item.F_ComingOut,
                //入货柜量
                F_ComingPut:item.F_ComingPut,
              //  类型
                F_Type:item.F_Type,
              //  仓库名称
                F_WarehouseName:item.F_WarehouseName

              }
              }),
            roam: false,
            focusNodeAdjacency: true,
            itemStyle: {
              normal: {
                color: '#FFC06D',
              }
            },
            tooltip: {
                trigger: "item",
              backgroundColor:'rgba(0, 23, 78, .9)',
              textStyle:{
                  color:'#D0DEF6'
              },
              borderColor:'none',
              borderWidth:0,
                formatter: (res) => {
                  if (res.data != null) {
                    return `<div class="toast">
          <div class="titles">${res.data.F_WarehouseName}</div>
     <div style="display:flex;justify-content: space-between;margin-top16px;">
     <div class="font-sizeck" v-if="res.data.F_Area == 1">仓库面积：${res.data.F_Area}</div>
     <div class="font-sizeck" v-if="res.data.F_Area == 2">云仓面积：${res.data.F_Area}</div>
     </div>
     <div style="display:flex;justify-content: space-between;margin-top16px;">
     <div class="font-sizeck">接收货品：</div>
     <div class="font-sizeck" >${res.data.F_ProductTypeName}</div>
     </div>
     <div style="display:flex;justify-content: space-between; margin-top16px;">
     <div class="font-sizeck">出 柜 量：${res.data.F_ComingOut}/柜</div>
     <div class="font-sizeck">收 柜 量：${res.data.F_ComingPut}</div>
     </div>
          </div>`;
                  } else {
                    return "";
                  }
                },
            },
            rippleEffect: {
              //涟漪特效
              period: 4, //动画时间，值越小速度越快
              brushType: 'stroke', //波纹绘制方式 stroke, fill
              scale: 3, //波纹圆环最大限制，值越大波纹越大
              opacity: 1,
            },
            symbol: 'circle',
            symbolSize: function () {
              return 8; //圆环大小
            },
            hoverAnimation: true,
            label: {
              normal: {
                show: false,
                formatter: '{b}',
                position: 'right',
              }
            },
            emphasis: {
              lineStyle: {
                width: 2
              }
            }
          },
          // {
          //   name: '仓库终点',
          //   type: 'effectScatter',
          //   coordinateSystem: 'geo',
          //   layout: 'none',
          //   data: that.convertDotData(that.lineData),
          //   roam: false,
          //   focusNodeAdjacency: true,
          //   tooltip: {
          //     show: true,
          //   },
          //   rippleEffect: {
          //     //涟漪特效
          //     period: 4, //动画时间，值越小速度越快
          //     brushType: 'stroke', //波纹绘制方式 stroke, fill
          //     scale: 3 //波纹圆环最大限制，值越大波纹越大
          //   },
          //   symbol: 'circle',
          //   symbolSize: function () {
          //     return 4; //圆环大小
          //   },
          //   itemStyle: {
          //     normal: {
          //       color: '#3EFFEC',
          //       opacity: 0.5,
          //     }
          //   },
          //   hoverAnimation: true,
          //   label: {
          //     normal: {
          //       show: false,
          //       formatter: '{b}',
          //       position: 'right',
          //     }
          //   },
          //   emphasis: {
          //     lineStyle: {
          //       width: 2
          //     }
          //   }
          // }
        ]
      };
      myChart.setOption(option);

    },
    // 散点
    convertDotData(data) {
      let res = [];
      for (let i = 0; i < data.length; i++) {
        let geoCoord = Site[data[i].name];
        if (geoCoord) {
          res.push({
            name: data[i].name,
            value: geoCoord
          })
        }
      }
      return res
    }
  }
}
</script>

<style scoped>
.map-box {
  width: 700px;
  height: 587px;
  overflow: hidden;
  position: relative;
}
.select {
  padding: 12px 24px;
  display: flex;
  justify-content: right;
}
.title {
  width: 700px;
  font-size: 20px;
  padding: 14px 0;
  background: linear-gradient(90deg, rgba(5, 63, 152, 0) 0%, #053F98 47%, rgba(5, 63, 152, 0) 100%);
  color: #00CAF0;
}
.toast{
  padding: 12px 19px;
}
.titles{
  text-align: center;
  padding-bottom: 12px;
  font-size: 12px;
  font-weight: 400;
  color: #FFFFFF;
}
.font-sizeck{
  font-size: 12px;
  font-weight: 400;
  color: #D0DEF6;
}
.title-line {
  width: 100%;
  height: 1px;
  background: linear-gradient(90deg, rgba(1, 98, 159, 0) 10%, rgba(4, 104, 165, 0.09) 13%, rgba(15, 124, 183, 0.36) 21%, rgba(24, 141, 198, 0.59) 29%, rgba(31, 154, 210, 0.77) 37%, rgba(36, 163, 219, 0.89) 43%, rgba(39, 169, 224, 0.97) 49%, #29ABE2 54%, rgba(39, 168, 223, 0.97) 57%, rgba(36, 162, 218, 0.9) 63%, rgba(31, 152, 208, 0.77) 69%, rgba(24, 137, 195, 0.6) 76%, rgba(15, 118, 178, 0.37) 83%, rgba(5, 96, 157, 0.1) 90%, rgba(1, 88, 150, 0) 93%);
  opacity: 1;
}
/deep/ .el-radio-button .el-radio-button__inner {
  font-size: 12px;
  padding: 8px 9px;
  background: #001448;
  color: #4A9EFF;
  border: 1px solid #4A9EFF !important;
}

/deep/ .el-radio-button__orig-radio:checked + .el-radio-button__inner {
  background-color: #022568;
  color: #4A9EFF;
}
/deep/ .el-table__body-wrapper {
  scrollbar-width: none;
}
/deep/ .el-radio-button__inner {
  border: none;
}
/deep/ .el-radio-button:first-child .el-radio-button__inner {
  border: none;
}
.map {
  width: 700px;
  height: 538px;
}
</style>
