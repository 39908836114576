<template>
  <div class="wrap">
    <dv-border-box-1>
      <div style="display: flex;justify-content: space-between;margin:20px 15px">
    <div class="card" v-for="(item,index) in styleList" :key="index">
      <div class="line1">
        <div class="block" :class="item.color"></div>
        <div>{{ item.title }}</div>
        <div class="line3">
          <div class="line3-item up" v-if="cardData[index].up > 0">
            <img class="line3-icon" src="@/assets/leftIcon/up.png" alt="">
            <span>{{ cardData[index].F_TransactionProportion }}%</span>
          </div>
          <div class="line3-item down" v-else>
            <img class="line3-icon" src="@/assets/leftIcon/down.png" alt="">
            <span>{{ cardData[index].F_TransactionProportion }}%</span>
          </div>
          <span>同比上周</span>
        </div>
      </div>
      <div class="line2">
        <el-statistic class="line2-number" :class="item.color" group-separator="," :value="cardData[index].F_Number"></el-statistic>
        <img class="line2-icon" :src="item.imgUrl">
      </div>
    </div>
    </div>
    </dv-border-box-1>
<!--    <border-line></border-line>-->
  </div>
</template>

<script>
// import BorderLine from "@/components/BorderLine/BorderLine.vue";
import Vue from "vue";
import {Statistic} from "element-ui"
Vue.use(Statistic)
export default {
  name: "TopWrap",
  // components: {BorderLine},
  data() {
    return {
      styleList: [
        {
          title: '货柜出口量',
          imgUrl: require('@/assets/rigthIcon/icon-01.png'),
          color: 'white',
        },
        {
          title: '货柜进口量',
          imgUrl: require('@/assets/rigthIcon/icon-02.png'),
          color: 'white',
        },
        {
          title: '当月进口订单',
          imgUrl: require('@/assets/rigthIcon/icon-03.png'),
          color: 'white',
        },
        {
          title: '当月出口订单',
          imgUrl: require('@/assets/rigthIcon/icon-04.png'),
          color: 'white',
        },
      ],
      cardData:[
        {
          F_Number: 0,
        },
        {
          F_Number: 0,
        },
        {
          F_Number: 0,
        },
        {
          F_Number: 0,
        },
      ],
    }
  },
  created() {
    this.getorderInfo()
  },
  methods:{
    getorderInfo(){
      const name = ['货柜出口数量', '货柜进口数量', '当月进口订单', '当月出口订单']
      this.api.getOrderTransactionData().then(res => {
            this.cardData = res.data.filter(item => name.includes(item.F_OrderCategoryName));
            this.cardData.sort((a, b) => name.indexOf(a.F_OrderCategoryName) - name.indexOf(b.F_OrderCategoryName));
            console.log(res)
          }
      )
    },
  }
}
</script>

<style scoped>
.wrap {
  width: 1150px;
  height: 170px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(0, 46, 112, 0.3);
}

.card {
  width: 270px;
  height: 122px;
  background: #07225b;
  border-radius: 8px;
}

.line1 {
  font-size: 14px;
  color: white;
  text-align: left;
  margin: 16px 22px 0;
  display: flex;
  align-items: center;
  /*justify-content: flex-end;*/
}

.block {
  margin-right: 6px;
  width: 2px;
  height: 12px;
  opacity: 1;
}

.line2 {
  margin: 0 22px;
  align-items: center;
}
.line2-icon{
  width: 100%;
  height: 100%;
}
.line2-number{
  margin-left: 12px;
  background: rgba(255, 255, 255, 0) !important;
}

/deep/ .el-statistic .con .number {
  font-size: 24px;
  font-weight: bold;
}

/deep/ .el-statistic .con{
  color: inherit;
  justify-content: start;
}
.white{
  background: #FFFFFF;
  color: #FFFFFF !important;
}
.line3 {
  display: flex;
}

.line3-item {
  display: flex;
  align-items: center;
  margin-right: 12px;
  margin-left: 18px;
}

.line3-icon{
  width: 10px;
  height: 6px;
}

.down {
  color: #FF5151;
}

.up {
  color: #0DD699;
}
</style>
