import Vue from 'vue'
import App from './App.vue'
import VScaleScreen from 'v-scale-screen'
import * as echarts from 'echarts'
import ElementUi from 'element-ui'
import dataV from '@jiaminghi/data-view'
import 'element-ui/lib/theme-chalk/index.css'
import api from './utils/api.js'


Vue.use(ElementUi)
Vue.use(VScaleScreen)
Vue.use(dataV)
Vue.config.productionTip = false
Vue.prototype.$echarts = echarts
Vue.prototype.api = api

new Vue({
  render: h => h(App),
}).$mount('#app')
