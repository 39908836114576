<template>
    <div style="display: flex">
        <ranking-table title="货代公司出柜量排行" :head-list="tableHead1" :table-data="companyList"
                       style="margin-right: 16px"></ranking-table>
        <ranking-table title="船司延误排行" :head-list="tableHead2" :table-data="delayList"
                       style="margin-right: 16px"></ranking-table>
        <ranking-table title="仓库出入库量排行" :head-list="tableHead3" :table-data="amountList"
                       style="margin-right: 16px"></ranking-table>
        <ranking-table title="仓库面积排行" :head-list="tableHead4" :table-data="areaList"></ranking-table>
    </div>
</template>

<script>
import RankingTable from "@/components/Middle/RankingTable.vue";

export default {
    name: "AllTable",
    components: {RankingTable},
    data() {
        return {
            companyList: [],
            delayList: [],
            amountList: [],
            areaList: [],
        }
    },
    created() {
        this.getCompanyData()
        this.getDelayData()
        this.getWarehouseMapData()
    },
    methods: {
        async getCompanyData() {
            let res = await this.api.getCompanyRListData()
          this.companyList= res.data
        },

        async getDelayData() {
            let res = await this.api.getDelayListData()
            this.delayList= res.data
        },

        async getWarehouseMapData() {
            let {data} = await this.api.getWarehouseMapData()
            data.map(item => {
                item.total = item.F_ComingOut + item.F_ComingPut + 'm³'
                item.area = item.F_Area + 'm²'
            })
            this.amountList = JSON.parse(JSON.stringify(data))
            this.areaList = JSON.parse(JSON.stringify(data))
        }
    }
}
</script>

<style scoped>

</style>

<script setup>
const tableHead1 = [
    {
        name: '货代公司名称',
        prop: 'F_CompanyName',
        className: 'tableName',
        width: 'auto',
    },
    {
        name: '柜量',
        prop: 'F_Count',
        width: "100",
        className: 'tableNumber'
    },
]

const tableHead2 = [
    {
        name: '船司名称',
        prop: 'F_CompanyName',
        className: 'tableName',
        width: 'auto',
    },
    {
        name: '柜量',
        prop: 'F_Count',
        width: "100",
        className: 'tableNumber'
    },
]

const tableHead3 = [
    {
        name: '仓库名称',
        prop: 'F_WarehouseName',
        className: 'tableName',
        width: 'auto',
    },
    {
        name: '出入库量/体积',
        prop: 'total',
        width: "100",
        className: 'tableNumber'
    },
]

const tableHead4 = [
    {
        name: '仓库名称',
        prop: 'F_WarehouseName',
        className: 'tableName',
        width: 'auto',
    },
    {
        name: '面积',
        prop: 'area',
        width: "100",
        className: 'tableNumber'
    },
]
</script>
