import { render, staticRenderFns } from "./outboundGoodsTable.vue?vue&type=template&id=53c8fd79&scoped=true&"
import script from "./outboundGoodsTable.vue?vue&type=script&lang=js&"
export * from "./outboundGoodsTable.vue?vue&type=script&lang=js&"
import style0 from "./outboundGoodsTable.vue?vue&type=style&index=0&id=53c8fd79&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53c8fd79",
  null
  
)

export default component.exports