<template>
    <div class="card">
        <div class="title-line"></div>
        <div class="title">大宗商品出口数据</div>
        <div class="title-line"></div>
        <div class="item">
            <div class="">单位：万吨</div>
<!--            <el-tabs class="select" @tab-click="changeDateRange">-->
<!--                <el-tab-pane label="今日" class="select-item"></el-tab-pane>-->
<!--                <el-tab-pane label="本周" class="select-item"></el-tab-pane>-->
<!--                <el-tab-pane label="本月" class="select-item"></el-tab-pane>-->
<!--            </el-tabs>-->
            <div class="date-pick">
                <el-date-picker
                        v-model="form.startDate"
                        type="date"
                        placeholder="起始时间"
                        :picker-options="beginDate"
                        @change="changeStart"
                />
                <div style="margin: 0 6px;height: 1px;width: 8px;background: #4A9EFF;"></div>
                <el-date-picker
                        v-model="form.endDate"
                        type="date"
                        placeholder="结束时间"
                        :picker-options="endDate"
                        @change="changeEnd"
                />
            </div>
        </div>
        <div class="chart" id="ExportLine"></div>
        <border-line></border-line>
    </div>
</template>

<script>
import BorderLine from "@/components/BorderLine/BorderLine.vue";

export default {
    name: "ImportLineChart",
    components: {BorderLine},
    data() {
        return {
            form: {
                startDate: '',
                endDate: '',
            },
            submitData: {
                // dateRange: 1,
                startDate: '',
                endDate: '',
            },
            list: [],
        }
    },
    mounted() {
        this.getData()
    },
    computed: {
        beginDate() {
            const that = this;
            return {
                disabledDate(time) {
                    if (that.form.endTime) {
                        //如果结束时间不为空，则小于结束时间
                        return (
                            time.getTime() > new Date(that.form.endTime).getTime()
                        );
                    }
                }
            }
        },
        endDate() {
            const that = this;
            return {
                disabledDate(time) {
                    if (that.form.beginTime) {
                        //如果开始时间不为空，则结束时间大于开始时间
                        console.log(that.form)
                        return (
                            time.getTime() < new Date(that.form.beginTime).getTime()
                        );
                    }
                }
            }
        },
    },
    methods: {
        changeStart(val) {
          console.log(val)
            this.submitData.startDate = this.translateDate(val)
        },
        changeEnd(val) {
          console.log(val)
            this.submitData.endDate = this.translateDate(val)
            this.getData()
        },
        // changeDateRange(item) {
        //     this.submitData.dateRange = Number(item.index) + 1
        //     this.getData()
        // },

        getData() {
            let data = this.submitData
            this.api.getExportData(data).then(res => {
                if(res.data.length !== 0) {
                    this.list = res.data
                }
                this.draw()
            })
        },

        draw() {
            let myChart = this.$echarts.init(document.getElementById('ExportLine'));
            let option = {
                tooltip: {
                    trigger: 'axis'
                },
                grid: {
                    top: '3%',
                    left: '20px',
                    right: '20px',
                    bottom: '8%',
                    containLabel: true,
                    show: false,
                },
                color: ['#70FEEB'],
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    alignTicks: true,

                    // data: this.xData,
                    axisLine: {
                        lineStyle: {
                            color: '#4A9EFF', //x轴的颜色
                            width: 1, //轴线的宽度
                        },
                    },
                    axisLabel: {
                        interval: 0,
                        color: '#4A9EFF',
                        fontSize: '12px',
                        width: '48px',
                        overflow: 'break',
                        // 更改显示文字显示形态 让他每三个字符就换行
                        formatter: function (value) {
                            //rowMAx 控制一行多少字
                            let rowMAx = 4
                            let overValue = ''
                            for (let i = 0; i < value.length; i++) {
                                if ((i % rowMAx === 0) && (i !== 0)) {
                                    overValue += '\n'
                                    overValue += value[i]
                                } else {
                                    overValue += value[i]
                                }
                            }
                            return overValue
                        }
                    },
                },
                yAxis: {
                    type: 'value',
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: '#4A9EFF', //x轴的颜色
                            width: 1, //轴线的宽度
                        },
                    },
                    axisLabel: {
                        color: '#4A9EFF',
                        fontSize: '12px',
                        margin: 6,
                    },
                    splitLine: {
                        show: true,
                        lineStyle: {
                            color: 'rgba(74,158,255, 0.4)',
                        }
                    },
                },
                dataset: {
                    dimensions: [
                        'F_Name', 'F_Count'
                    ],
                    source: this.list,
                },
                series: [
                    {
                        name: '流入量',
                        type: 'line',
                        stack: 'Total',
                        // data: this.yData
                    },
                ]
            };
            myChart.setOption(option)
        },

        translateDate(msec) {
            let datetime = new Date(msec);
            let year = datetime.getFullYear();
            let month = datetime.getMonth();
            let date = datetime.getDate();
            // let hour = datetime.getHours();
            // let minute = datetime.getMinutes();
            // let second = datetime.getSeconds();

            // let result1 = year +
            //     '-' +
            //     ((month + 1) >= 10 ? (month + 1) : '0' + (month + 1)) +
            //     '-' +
            //     ((date + 1) < 10 ? '0' + date : date) +
            //     ' ' +
            //     ((hour + 1) < 10 ? '0' + hour : hour) +
            //     ':' +
            //     ((minute + 1) < 10 ? '0' + minute : minute) +
            //     ':' +
            //     ((second + 1) < 10 ? '0' + second : second);

            let result2 = year +
                '-' +
                ((month + 1) >= 10 ? (month + 1) : '0' + (month + 1)) +
                '-' +
                ((date + 1) < 10 ? '0' + date : date);

            // let result = {
            //     hasTime: result1,
            //     withoutTime: result2
            // };

            return result2;
        }
    }
}
</script>

<style scoped>
.card {
    width: 568px;
    height: 341px;
    position: relative;
}

.title {
    width: 100%;
    padding: 10px 0;
    background: linear-gradient(90deg, rgba(5, 63, 152, 0) 0%, #053F98 47%, rgba(5, 63, 152, 0) 100%);
    font-size: 16px;
    color: #00CAF0;
}

.title-line {
    width: 100%;
    height: 1px;
    background: linear-gradient(90deg, rgba(1, 98, 159, 0) 10%, rgba(4, 104, 165, 0.09) 13%, rgba(15, 124, 183, 0.36) 21%, rgba(24, 141, 198, 0.59) 29%, rgba(31, 154, 210, 0.77) 37%, rgba(36, 163, 219, 0.89) 43%, rgba(39, 169, 224, 0.97) 49%, #29ABE2 54%, rgba(39, 168, 223, 0.97) 57%, rgba(36, 162, 218, 0.9) 63%, rgba(31, 152, 208, 0.77) 69%, rgba(24, 137, 195, 0.6) 76%, rgba(15, 118, 178, 0.37) 83%, rgba(5, 96, 157, 0.1) 90%, rgba(1, 88, 150, 0) 93%);
    opacity: 1;
}

.item {
    padding: 20px 20px 20px;
    display: flex;
    color: #4A9EFF;
    font-size: 12px;
    align-items: center;
}

.chart {
    width: 100%;
    height: 229px;
}

.select {
    margin-left: 110px;
}

.date-pick {
    /*transform: translateY(-10px);*/
    margin-left: auto;
    display: flex;
    align-items: center;
}

/deep/ .el-tabs__header {
    margin: 0;
}

/deep/ .el-tabs__item {
    color: #4A9EFF;
    padding: 0 6px;
    height: auto;
    line-height: 22px;
}

/deep/ .el-tabs__nav-wrap::after {
    display: none;
}

/deep/ .is-active {
    color: #00CAF0 !important;
}

/deep/ .el-date-editor.el-input, .el-date-editor.el-input__inner {
    width: 110px;
    height: 28px;
}

/deep/ .el-input__inner {
    height: 28px;
    font-size: 12px;
    color: #4A9EFF;
    border: 1px solid #4A9EFF;
    background: rgba(0, 0, 0, 0);
}

/deep/ .el-input__inner::placeholder {
    color: #4A9EFF;
}

/deep/ .el-input--prefix .el-input__inner {
    padding-left: 10px;
}

/deep/ .el-input--suffix .el-input__inner {
    padding-right: 30px;
}

/deep/ .el-input__prefix {
    display: none;
}

/deep/ .el-input__suffix {
    display: none;
}
</style>
