<template>
    <div class="wrap">
        <div class="info-item">
            <div class="item-title" style="color: #00CAF0;">
                在途订单
            </div>
            <el-statistic class="number" group-separator="," :value="taskOrder.F_Number"></el-statistic>
            <div class="item-line3" style="font-size: 14px">
                同比上周
                <div class="up" v-if="taskOrder.F_RiseDecline === 1">
                    <img class="line3-icon" src="@/assets/leftIcon/up.png" alt="">
                    <span>{{ taskOrder.F_TransactionProportion }}%</span>
                </div>
                <div class="down" v-else>
                    <img class="line3-icon" src="@/assets/leftIcon/down.png" alt="">
                    <span>{{ taskOrder.F_TransactionProportion }}%</span>
                </div>
            </div>
        </div>
        <div class="gap"></div>
        <div class="info-item">
            <div class="item-title">
                延误订单
            </div>
            <div class="number">{{ delayOrder.F_Number }}</div>
            <div class="item-line3" style="font-size: 14px">
                同比上周
                <div class="up" v-if="taskOrder.F_RiseDecline === 1">
                    <img class="line3-icon" src="@/assets/leftIcon/up.png" alt="">
                    <span>{{ taskOrder.F_TransactionProportion }}%</span>
                </div>
                <div class="down" v-else>
                    <img class="line3-icon" src="@/assets/leftIcon/down.png" alt="">
                    <span>{{ taskOrder.F_TransactionProportion }}%</span>
                </div>
            </div>
        </div>
        <div class="info-item">
            <div class="item-title">
                延误比例
            </div>
            <div class="number">{{ (delayOrder.F_Number/taskOrder.F_Number).toFixed(4) * 100 }}%</div>
            <div class="item-line3" style="font-size: 14px">
                同比上周
                <div class="up" v-if="taskOrder.F_RiseDecline === 1">
                    <img class="line3-icon" src="@/assets/leftIcon/up.png" alt="">
                    <span>{{ taskOrder.F_TransactionProportion }}%</span>
                </div>
                <div class="down" v-else>
                    <img class="line3-icon" src="@/assets/leftIcon/down.png" alt="">
                    <span>{{ taskOrder.F_TransactionProportion }}%</span>
                </div>
            </div>
        </div>
        <border-line></border-line>
    </div>
</template>

<script>
import BorderLine from "@/components/BorderLine/BorderLine.vue";

export default {
    name: "OrderInfo",
    components: {BorderLine},
    data() {
        return {
            taskOrder: {},
            delayOrder: {}
        }
    },
    created() {
        this.getData()
    },
    methods: {
        async getData() {
            let res = await this.api.getOrderLogisticsData()
            res.data.map(item => {
                if (item.F_OrderCategoryName === '在途订单') {
                    this.taskOrder = item
                }
                if (item.F_OrderCategoryName === '延误订单') {
                    this.delayOrder = item
                }
            })
        }
    }
}
</script>

<style scoped>
.wrap {
    width: 404px;
    height: 88px;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
}

.info-item {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.item-title {
    font-size: 14px;
    color: #4A9EFF;
}

.gap {
    width: 1px;
    height: 50px;
    opacity: 1;
    background: #00CAF0;
}

.number {
    font-size: 24px;
    font-weight: bold;
    color: #FFFFFF;
}

/deep/ .el-statistic .con .number {
    font-size: 24px;
    font-weight: bold;
    color: white;
}

.item-line3 {
    display: flex;
    color: rgba(255, 255, 255, 0.6);
}

.up {
    display: flex;
    align-items: center;
}

.line3-icon {
    margin-left: 8px;
    margin-right: 4px;
}
</style>
