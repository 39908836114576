<template>
    <div class="pro-card">
        <div class="title">进出口比例</div>
        <div class='circle' id="IEProportion"></div>
        <border-line></border-line>
    </div>
</template>

<script>
import BorderLine from "@/components/BorderLine/BorderLine.vue";

export default {
    components: {BorderLine},
    data() {
        return {
            aData: []
        }
    },
    created() {
        this.getData()
    },
    mounted() {
    },
    methods: {
         getData() {
            this.api.getProductOutIn().then(res => {
                  this.aData = res.data
                this.draw()
            })
        },

        draw() {
            let myChart = this.$echarts.init(document.getElementById('IEProportion'));
            let option = {
                tooltip: {
                    trigger: 'item'
                },
                legend: {
                    show: false,
                    top: '5%',
                    left: 'center'
                },
                color: ['#006fff','#ff5152'],
                series: [
                    {
                        type: 'pie',
                        radius: ['20%', '70%'],
                        startAngle: 125,
                        data: this.aData,
                        labelLine: {
                            show: true,
                            position: 'outside',
                            length: 10,
                            length2: 40
                        },
                        emphasis: {
                            itemStyle: {
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        },
                        label: {
                            normal: {
                                position: 'outside',
                                show: true,
                                rotate: 0,
                                color: '#ffffff',
                                fontSize: '12px',
                                formatter: (d) => {
                                    return d.name + '（' +  d.value  + '次' +'）'
                                }
                            }
                        }
                    },
                    {
                        type: 'pie',
                        radius: ['20%', '70%'],
                        data: this.aData,
                        startAngle: 125,
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        },
                        label: {
                            normal: {
                                position: 'inside',
                                show: true,
                                textStyle: {
                                    color: '#ffffff',
                                    fontSize: '12px',
                                },
                                formatter: (d) => {
                                    return d.percent + '%'
                                }
                            }
                        }
                    }
                ]
            };
            myChart.setOption(option)
        }
    }
}
</script>

<style scoped>
.pro-card {
    width: 444px;
    height: 220px;
    position: relative;
}

.title {
    width: 100%;
    font-size: 20px;
    padding: 10px 0;
    background: linear-gradient(90deg, rgba(5, 63, 152, 0) 0%, #053F98 47%, rgba(5, 63, 152, 0) 100%);
    color: rgba(0, 202, 240, 1);
}

.circle {
    width: 100%;
    height: 180px;
}
</style>
