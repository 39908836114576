<template>
    <div class="map-box">
        <div class="title">货柜迁徙图</div>
        <div class="title-line"></div>
        <div class="map" id="lineMap"></div>
        <BorderLine></BorderLine>
    </div>
</template>

<script>
import {Site} from "@/assets/js/mapSite";
import {getEnd, getSource, lightArea, worldNameZh} from "@/assets/js/mapTool";
import {world} from "@/assets/js/world";
import BorderLine from "@/components/BorderLine/BorderLine.vue";

export default {
    name: "LineMap",
    components: {BorderLine},
    data() {
        return {
            lineData: [],
            startData: [],
            endData: []
        }
    },
    mounted() {
        this.getData()
    },
    methods: {
        getData() {
            this.api.getContainerMigrationData().then(res => {
                this.lineData = res.data
                this.startData = getSource(res.data)
                this.endData = getEnd(res.data)
                this.draw()
            })
        },

        // 航线
        convertLineData(data) {
            let res = []
            for (let i = 0; i < data.length; i++) {
                let dataItem = data[i]
                let from = Site[dataItem.source];
                let to = Site[dataItem.target];
                if (from && to) {
                    res.push({
                        coords: [from, to]
                    })
                }
            }
            return res
        },
        // 散点
        convertDotData(data) {
            let res = [];
            for (let i = 0; i < data.length; i++) {
                let geoCoord = Site[data[i].name];
                if (geoCoord) {
                    res.push({
                        name: data[i].name,
                        value: geoCoord
                    })
                }
            }
            return res
        },

        draw() {
            const that = this
            // eslint-disable-next-line no-unused-vars
            let iconPath = "path://M478,462.70001220703125c43.29998779296875,0,78.29998779296875,-35.100006103515625,78.29998779296875,-78.30001831054688c0,-43.29998779296875,-35.0999755859375,-78.29998779296875,-78.29998779296875,-78.29998779296875c-43.29998779296875,0,-78.29998779296875,35.100006103515625,-78.29998779296875,78.29998779296875c0,43.300018310546875,35,78.30001831054688,78.29998779296875,78.30001831054688Z M714.5,384.3999938964844m-78.29998779296875,0a78.3,78.3,0,1,0,156.5999755859375,0a78.3,78.3,0,1,0,-156.5999755859375,0Z M415.20001220703125,648.9000244140625l11.5999755859375,55.0999755859375l345.4000244140625,-72.5999755859375l-11.60003662109375,-55.10003662109375Z M835.0999755859375,62L188.89999389648438,62C118.9000015258789,62,62,118.9000015258789,62,188.89999389648438l0,646.1999816894531c0,70,56.900001525878906,126.9000244140625,126.89999389648438,126.9000244140625l646.1999816894531,0c70,0,126.9000244140625,-56.9000244140625,126.9000244140625,-126.9000244140625L962,188.89999389648438c0,-69.99999237060547,-56.9000244140625,-126.89999389648438,-126.9000244140625,-126.89999389648438Zm70.70001220703125,773.0999755859375c0,38.9000244140625,-31.70001220703125,70.60003662109375,-70.5999755859375,70.60003662109375L188.89999389648438,905.7000122070312c-38.899993896484375,0,-70.59999084472656,-31.70001220703125,-70.59999084472656,-70.60003662109375L118.30000305175781,188.89999389648438c0,-38.899993896484375,31.699996948242188,-70.59999084472656,70.59999084472656,-70.59999084472656l646.1999816894531,0c39,0,70.60003662109375,31.699996948242188,70.60003662109375,70.59999084472656l0,646.1999816894531Z"
            let myChart = this.$echarts.init(document.getElementById('lineMap'));
            lightArea.map(item => {
                item.itemStyle = {}
                item.itemStyle.areaColor = '#2B7BDC'
            })

            this.$echarts.registerMap('world', world)
            let option = {
                geo: [
                    {
                        name: '1',
                        type: 'map',
                        map: 'world',
                        nameMap: worldNameZh,
                        roam: false,
                        zoom: 2.1,
                        center: [80.617733, 0.792818],
                        emphasis: {
                            label: {
                                show: false
                            }
                        },
                        itemStyle: {
                            normal: {
                                borderWidth: 2,
                                borderColor: '#2065d5',
                                areaColor: '#2065d5',
                            },
                            emphasis: {
                                areaColor: '#2B7BDC' // 鼠标悬浮时的颜色
                            }
                        },
                        regions: lightArea,
                        selectedMode: false,
                    }
                ],
                tooltip: {
                    backgroundStyle: {
                        color: 'red'
                    }
                },
                series: [
                    {
                        type: "lines",
                        coordinateSystem: "geo",
                        z: 99,
                        zlevel: 2,
                        effect: {
                            show: true,
                            period: 6,//箭头指向速度,值越小速度越快
                            trailLength: 0.3,//特效尾迹长度[0,1]值越大，尾迹越长重
                            symbol: 'circle',//箭头图标
                            symbolSize: 12,//图标大小
                        },
                        tooltip: {
                            show: false,
                        },
                        lineStyle: {
                            normal: {
                                type: 'solid',
                                color: '#3effec',
                                width: 1,//扉迹线条宽度
                                opacity: 1,//尾迹线条透明度
                                curveness: .3//尾迹线条曲直度
                            }
                        },
                        data: that.convertLineData(that.lineData),
                    },
                    {
                        name: '航运起点',
                        type: 'effectScatter',
                        coordinateSystem: 'geo',
                        layout: 'none',
                        data: that.convertDotData(that.startData),
                        roam: false,
                        focusNodeAdjacency: true,
                        itemStyle: {
                            normal: {
                                color: '#FFC06D',
                            }
                        },
                        tooltip: {
                            show: true,

                        },
                        rippleEffect: {
                            //涟漪特效
                            period: 4, //动画时间，值越小速度越快
                            brushType: 'stroke', //波纹绘制方式 stroke, fill
                            scale: 3, //波纹圆环最大限制，值越大波纹越大
                            opacity: 1,
                        },
                        symbol: 'circle',
                        symbolSize: function () {
                            return 8; //圆环大小
                        },
                        hoverAnimation: true,
                        label: {
                            normal: {
                                show: false,
                                formatter: '{b}',
                                position: 'right',
                            }
                        },
                        emphasis: {
                            lineStyle: {
                                width: 2
                            }
                        }
                    },
                    {
                        name: '航运终点',
                        type: 'effectScatter',
                        coordinateSystem: 'geo',
                        layout: 'none',
                        data: that.convertDotData(that.endData),
                        roam: false,
                        focusNodeAdjacency: true,
                        tooltip: {
                            show: true,
                        },
                        rippleEffect: {
                            //涟漪特效
                            period: 4, //动画时间，值越小速度越快
                            brushType: 'stroke', //波纹绘制方式 stroke, fill
                            scale: 3 //波纹圆环最大限制，值越大波纹越大
                        },
                        symbol: 'circle',
                        symbolSize: function () {
                            return 4; //圆环大小
                        },
                        itemStyle: {
                            normal: {
                                color: '#3EFFEC',
                                opacity: 0.5,
                            }
                        },
                        hoverAnimation: true,
                        label: {
                            normal: {
                                show: false,
                                formatter: '{b}',
                                position: 'right',
                            }
                        },
                        emphasis: {
                            lineStyle: {
                                width: 2
                            }
                        }
                    }
                ]
            };
            myChart.setOption(option);
        }
    }
}
</script>

<style scoped>
.map-box {
    width: 700px;
    height: 587px;
    overflow: hidden;
    position: relative;
}

.title {
    width: 700px;
    font-size: 20px;
    padding: 14px 0;
    background: linear-gradient(90deg, rgba(5, 63, 152, 0) 0%, #053F98 47%, rgba(5, 63, 152, 0) 100%);
    color: #00CAF0;
}

.title-line {
    width: 100%;
    height: 1px;
    background: linear-gradient(90deg, rgba(1, 98, 159, 0) 10%, rgba(4, 104, 165, 0.09) 13%, rgba(15, 124, 183, 0.36) 21%, rgba(24, 141, 198, 0.59) 29%, rgba(31, 154, 210, 0.77) 37%, rgba(36, 163, 219, 0.89) 43%, rgba(39, 169, 224, 0.97) 49%, #29ABE2 54%, rgba(39, 168, 223, 0.97) 57%, rgba(36, 162, 218, 0.9) 63%, rgba(31, 152, 208, 0.77) 69%, rgba(24, 137, 195, 0.6) 76%, rgba(15, 118, 178, 0.37) 83%, rgba(5, 96, 157, 0.1) 90%, rgba(1, 88, 150, 0) 93%);
    opacity: 1;
}

.map {
    width: 700px;
    height: 538px;
}
</style>
